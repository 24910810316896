import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 4
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"FIVE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"How are sports related to a girl’s social well-being?"}
        answers={[
          "Sports help girls form social hierarchies and cliques.",
          "Sports help girls develop leadership skills, self-reliance, and self-discipline.",
          "Sports help girls define their success exclusively by their athletic performance.",
          "Sports help girls choose friends based on athletic ability.",
        ]}
        correctAnswerNumber={1}
        answerDetail={
          "Sports allow girls structured exposure to larger networks that can benefit them in other areas of achievement. Sports are a way for girls to confidently enter the complex social hierarchies of high school. Girls who play sports are more likely to be involved in other extracurricular and community activities."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 5, 6)
